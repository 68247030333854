import React, { memo } from "react";
import { Menu, Image, Button, Label, Icon, Popup } from "semantic-ui-react";
import { getUser } from "../../../utils/common";
import { removeUserSession } from "../../../utils/common";
import logo from "../../../assets/images/written-logo.svg";
import noProfile from "../../../assets/images/userLogoImage.png"
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Navbar = () => {
  // const history = useHistory();
  const loggedInUser = getUser();
  const Logout = () => {
    removeUserSession();
    // history.push('/')
    window.location.reload();
  };
  return (
    <>
      <Menu inverted fluid size="large" tabular compact style={{ position: "fixed", zIndex: 999, top: -5 }} >
        <Menu.Item  >
          <Image src={logo} size="small" />
        </Menu.Item>
        {loggedInUser?._id && <Menu.Menu position="right">
          <Menu.Item>
            <div style={{ border: "1px solid #fff", borderRadius: "25px", padding: "0 5px", display: "flex", color: "#fff", alignItems: "center" }}>
              <Label style={{ backgroundColor: "inherit", height: "fit-content", color: "#fff", display: "flex", alignItems: "center" }}>
                <Image avatar spaced='right' src={noProfile} size="small" />
                <div>
                  <h5 style={{ margin: 0 }} title={`Name : ${loggedInUser?.name}`}>{loggedInUser?.name}</h5>
                  <span style={{ fontStyle: "italic" }} title={`Role : ${loggedInUser?.role}`}>{loggedInUser?.role}</span>
                </div>
              </Label>
              <Popup
                trigger={
                  <Icon
                    style={{ cursor: "pointer" }}
                    name="sign-out"
                    color="inherit"
                    circular
                    onClick={Logout} />
                }
                content="Logout" />
            </div>
          </Menu.Item>
        </Menu.Menu>}
      </Menu>
    </>
  );
};
export default memo(Navbar);